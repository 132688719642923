<template>
  <v-container fluid>
    <h2>Wettkampfbericht vor dem Wettkampf</h2>
    <v-row>
      <v-col cols="12">
        <base-edit-dialog
          :value="info.ersthelfer"
          label="Ersthelfer"
          id="null"
          textfield
          @input="({value}) => s('ersthelfer', value)"
          :disabled="disabled"
        />
      </v-col>
      <v-col cols="6">
        <v-checkbox
          v-model="info.unfallhilfe"
          label="Unfallhilfe anwesend?"
          @change="(value) => s('unfallhilfe', !!value)"
          :disabled="disabled"
        />
      </v-col>
      <v-col cols="6">
        <base-edit-dialog
          :value="info.rettungsdienst"
          label="Telefonnummer Rettungsdienst"
          id="null"
          textfield
          @input="({value}) => s('rettungsdienst', value)"
          :disabled="disabled"
        />
      </v-col>
      <v-col cols="12">
        <h2>Hallen und Geräteverhältnisse, Checkliste</h2>
        <p style="color:red;">Die Checkliste ist vom Oberkampfrichter vor dem Wettkampf zusammen mit den beiden
          Mannschaftsführern auszufüllen und  zu unterschreiben. Es gelten grundsätzlich die Normen des internationalen
          Turnerbundes FIG wie im Code de Pointage und im Gerätenormbuch der FIG beschrieben.</p>
        <ul>
          <li>Der Gastverein wurde vom Ausrichter über folgende Abweichungen gegenüber den FIG Normen bereits im Vorfeld des
            Wettkampfes informiert:</li>
        </ul>
      </v-col>
      <v-col cols="12">
        <base-edit-dialog
          :value="info.geraetenorm"
          label="Abweichungen von Gerätenorm"
          id="null"
          textarea
          @input="({value}) => s('geraetenorm', value)"
          :disabled="disabled"
        />
      </v-col>
      <v-col cols="12">
        <ul>
          <li>Beide Mannschaftsführer erklären sich mit den angegebenen Abweichungen gegenüber der Gerätenorm einverstanden.</li>
          <li>Der Heimverein erklärt, dass alle Geräte und Zusatzgeräte auf Sicherheit und Funktion überprüft wurden.</li>
          <li>Beide Mannschaftsführer erklären, dass die Hallen und Geräteverhältnisse in Ordnung sind und der Wettkampf
            sicher durchgeführt werden kann.</li>
          <li>Beide Mannschaftsführer und Trainer sind auf die verbotenen Teile für Jugendturner belehrt worden
            (vgl. Code de Pointage Kapitel 7 Artikel 27 Pkt. 3f).</li>
        </ul>
      </v-col>
      <v-col cols="12">
        <h2>Aufgaben des Oberkampfrichters</h2>
        <ul>
          <li>Identität der Turner geprüft, Lizenzen der Kampfrichter geprüft, Sitzordnung Kampfgericht.</li>
          <li>Einweisung von:
            <ul>
              <li>Linienrichter (falls vorhanden)</li>
              <li>Abstimmung mit Wettkampfleiter und EDV über den Wettkampfablauf</li>
            </ul>
          </li>
          <li>Auslosung der Startreihenfolge.</li>
          <li>Der Wettkampf kann nach den Bestimmungen der Ligaordnung und der Durchführungsbestimmungen durchgeführt werden.</li>
          <li>Unterschriften vor WK-Beginn OK/Heim/Gast.</li>
          <li style="color:red;font-weight:bold;">Nicht erfüllte Punkte und sonstige Unstimmigkeiten müssen hier im Bemerkungs-Feld eingegeben werden!</li>
        </ul>
      </v-col>
      <v-col cols="12">
        <base-edit-dialog
          :value="info.bemerkung"
          label="Sonstige Bemerkungen"
          id="null"
          textarea
          @input="({value}) => s('bemerkung', value)"
          :disabled="disabled"
        />
      </v-col>
      <v-col cols="12" v-if="!isReady">
        <h2 style="color:red;">Ersthelfer und Unfallhilfe oder Telefonnummer Rettungsdienst müssen ausgefüllt werden bevor der Wettkampfbericht unterschrieben oder heruntergeladen werden kann!</h2>
      </v-col>
      <v-col cols="12" v-else>
        <h2>Unterschriften direkt im Browser:</h2>
        <p>
          Die Verantwortlichen können direkt hier im Browser unterschreiben (bei einem Tablet mit Stift). Alternativ lässt sich
          der Wettkampfbericht auch ohne Unterschriften herunterladen, ausdrucken und unterschreiben. Dieser muss dann manuell
          abfotografiert und unter "Abschluss" wieder hochgeladen werden!
        </p>
        <p style="color:red;">
          Wichtig: Die Unterschriften müssen mit dem entsprechenden Button gespeichert werden! (Die Buttons verschwinden dann
          und es lässt sich nichts mehr daran ändern.) Sobald mindestens eine Unterschrift getätigt und gespeichert wurde lässt
          sich an den Eingabefeldern oben nichts mehr ändern!
        </p>
        <Unterschrift type="Oberkampfrichter" :name="ok | person" :image="getunterschrift('unterschrift_vor_ok.png')" @save="(d) => saveunterschrift('unterschrift_vor_ok.png', d)" :disabled="abgeschlossen" />
        <Unterschrift type="Mannschaftsverantwortlicher Heim" :name="heim | person" :image="getunterschrift('unterschrift_vor_heim.png')" @save="(d) => saveunterschrift('unterschrift_vor_heim.png', d)" :disabled="abgeschlossen" />
        <Unterschrift type="Mannschaftsverantwortlicher Gast" :name="gast | person" :image="getunterschrift('unterschrift_vor_gast.png')" @save="(d) => saveunterschrift('unterschrift_vor_gast.png', d)" :disabled="abgeschlossen" />
        <v-btn color="red" @click="reset()" v-if="!abgeschlossen">Unterschriften zurücksetzen</v-btn>
      </v-col>
      <v-col cols="12" v-if="isReady">
        <v-btn block @click="down2">Download vor WK</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { useGraphQL } from '@/plugins/graphql'
import { GRAPHQLlocal, GRAPHQLserver } from '@/env'
import gql from 'graphql-tag'
import { usePdf } from '../../../plugins/pdf'

export default {
  name: 'wkbericht',

  components: {
    Unterschrift: () => import('../general/unterschrift')
  },

  setup (props, context) {
    return {
      ...usePdf(context),
      ...useGraphQL(context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    },
    e: {
      type: Object,
      required: true
    }
  },

  computed: {
    ok () {
      return this.e.judges?.find(j => j.type === 'e1')?.person || {}
    },
    heim () {
      return this.e.teams?.find(t => t.home)?.trainer?.find(t => t.order === 1)?.person || {}
    },
    gast () {
      return this.e.teams?.find(t => !t.home)?.trainer?.find(t => t.order === 1)?.person || {}
    },
    info () {
      return this.e.info || {}
    },
    imageBase () {
      if (!GRAPHQLlocal) return '/uploads/'

      const base = GRAPHQLserver.match(/(wss?):\/\/([^/]*)/)
      return `http${base[1] === 'wss' ? 's' : ''}://${base[2]}/uploads/`
    },
    isReady () {
      return !!this.info.ersthelfer &&
        (!!this.info.unfallhilfe || !!this.info.rettungsdienst)
    },
    abgeschlossen () {
      return this.e.status === 'EventFinished'
    },
    disabled () {
      return !!this.getunterschrift('unterschrift_vor_ok.png') ||
        !!this.getunterschrift('unterschrift_vor_heim.png') ||
        !!this.getunterschrift('unterschrift_vor_gast.png') ||
        this.e.status === 'EventStarted' ||
        this.e.status === 'EventFinished'
    }
  },

  methods: {
    down2 () {
      this.wkbericht2(this.e, this.info.ersthelfer || '', !!this.info.unfallhilfe, this.info.rettungsdienst || '', this.info.geraetenorm || '')
    },
    s (field, value) {
      this.mutate({
        mutation: gql`mutation($id: UUID!,
          $ersthelfer: String, $unfallhilfe: Boolean, $rettungsdienst: String, $bemerkung: String,
          $geraetenorm: String, $wettkampfleiter: String, $hygienebeauftragter: String,
          $zuschauer: Int, $ende: String, $dauer: String, $special: String) {
          StbM2021WkInfoUpdate(id: $id,
            ersthelfer: $ersthelfer, unfallhilfe: $unfallhilfe, rettungsdienst: $rettungsdienst, bemerkung: $bemerkung,
            geraetenorm: $geraetenorm, wettkampfleiter: $wettkampfleiter, hygienebeauftragter: $hygienebeauftragter,
            zuschauer: $zuschauer, ende: $ende, dauer: $dauer, special: $special) { _id }
        }`,
        variables: {
          id: this.id,
          [field]: value
        }
      })
    },
    saveunterschrift (filename, filedata) {
      this.mutate({
        mutation: gql`mutation($id: UUID!, $filedata: String!, $filename: String!, $mimetype: String!) {
          StbM2021WkInfoUpdateAttachmentAdd(id: $id, filedata: $filedata, filename: $filename, mimetype: $mimetype) {
            info { attachments { _id filename mimetype } }
          }
        }`,
        variables: {
          id: this.id,
          filedata,
          filename,
          mimetype: 'image/png'
        }
      })
    },
    getunterschrift (filename) {
      const a = this.info.attachments?.find(a => a.filename === filename)
      return a ? this.imageBase + a._id : null
    },
    async reset () {
      const del = async (file) => {
        const a = this.info.attachments?.find(a => a.filename === file)?._id
        if (!a) return

        this.mutate({
          mutation: gql`mutation($id: UUID!, $file: UUID!) {
            StbM2021WkInfoUpdateAttachmentDelete(id: $id, file: $file) { info { attachments { _id filename mimetype } } }
          }`,
          variables: {
            id: this.id,
            file: a
          }
        })
      }

      if (await this.$root.$children[0].$refs.confirm.open('Wirklich zurücksetzen?', 'Es müssen alle Unterschriften anschließend erneut eingegeben werden!')) {
        del('unterschrift_vor_ok.png')
        del('unterschrift_vor_heim.png')
        del('unterschrift_vor_gast.png')
      }
    }
  }
}
</script>

<style scoped>

</style>
